import React from 'react';
import { graphql } from 'gatsby';
import { PortableText } from '@portabletext/react';
import FadeIn from '../components/FadeIn';
import Seo from '../components/Seo';

export const query = graphql`
	query legalPageQuery($_id: String!) {
		project: sanityLegalPage(_id: { eq: $_id }) {
			_id
			title
			seo {
				...seoFields
			}
			_rawContent
		}
	}
`;

const ProjectTemplate = ({ data }) => {
	const page = data && data.project;
	return (
		<>
			<Seo
				metaTitle={page.seo?.metaTitle || page.title}
				shareTitle={page.seo?.shareTitle || page.title}
				metaDesc={page.seo?.metaDesc}
				shareDesc={page.seo?.shareDesc}
			/>
			<h1 className="text-4xl lg:text-8xl mb-8 mr-8">{page.title}</h1>
			<div className="prose max-w-4xl">
				<FadeIn>
					<PortableText value={page._rawContent} />
				</FadeIn>
			</div>
		</>
	);
};

export default ProjectTemplate;
